<template>
	<div class="layout-main">
		<layout-header/>
		<navigation/>
		<v-main class="my-4">
			<v-container fluid class="layout-main__container">
				<transition name="route" mode="out-in">
					<router-view :key="$route.path"/>
				</transition>
			</v-container>
		</v-main>

		<v-dialog
			v-model="dialogChanges.visible"
			width="520">
			<v-card>
				<v-card-title>
					Изменения не сохранены
				</v-card-title>

				<v-card-text>
					У Вас есть несохраненные изменения, они будут утеряны. Продолжить?
				</v-card-text>

				<v-divider/>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						text
						@click="dialogChanges.visible=false">
						Нет
					</v-btn>
					<v-btn
						color="primary"
						text
						@click="dialogChanges.visible=false">
						Да
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialogDelete.visible" width="400">
			<v-card>
				<v-card-title class="justify-center">Подтвердите удаление</v-card-title>
				<v-divider/>
				<v-card-actions class="justify-center">
					<v-btn @click="dialogDelete.visible = false">Отмена</v-btn>
					<v-spacer/>
					<v-btn color="primary" :loading="dialogDelete.loading" @click="onDelete">Подтвердить</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialogError.visible" width="400">
			<v-card>
				<v-card-title class="justify-center">Возникла ошибка...</v-card-title>
				<v-card-text>
					{{dialogError.message}}
				</v-card-text>
				<v-divider/>
				<v-card-actions class="justify-center">
					<v-btn color="primary" @click="onErrorOk">Ок</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import Navigation from "./main/navigation";
import LayoutHeader from "./main/layout-header";

export default {
	name: "layout-main",
	components: {
		LayoutHeader,
		Navigation
	},
	computed: {
		dialogChanges() {
			return this.$store.state.dialogChanges;
		},
		dialogDelete() {
			return this.$store.state.dialogDelete;
		},
		dialogError() {
			return this.$store.state.dialogError;
		},
	},
	methods: {
		onDelete() {
			if (this.dialogDelete.callback) this.dialogDelete.callback();
		},
		onErrorOk() {
			this.dialogError.visible = false;
			if (this.dialogError.callback) this.dialogError.callback();
		}
	}
}
</script>

<style lang="scss">
.layout-main {
	&__container {
		@include up($md) {
			padding-left: 24px;
			padding-right: 24px;
		}
	}
}
</style>