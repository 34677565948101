<template>
	<v-app-bar class="header"
			   app
			   clipped-left
			   elevation="1"
	>
		<div class="header__wrapper d-flex justify-space-between align-center">
			<div class="d-flex">
				<v-app-bar-nav-icon @click.stop="toggleDrawer" class="d-lg-none"/>
				<div class="d-flex justify-start align-center">
					<svg version="1.1" viewBox="0 0 309 57" class="svg-hover svg-icon svg-fill" style="height: 16px;">
						<path pid="0"
							  d="M56.59 0H45.248v56.999H56.59V0zM87.826 11.4H76.483v45.599h11.343v-45.6zM118.981 0H107.64v56.999h11.341V0zM153.013 45.6h11.342V0h-11.342v45.6z"
							  fill="#73BCE4"></path>
						<path pid="1" d="M76.482 0H65.14v11.4h11.342V0z" fill="#4EA2D6"></path>
						<path pid="2" d="M99.17 0H76.48l11.348 11.4H99.17V0z" fill="#73BCE4"></path>
						<path pid="3"
							  d="M130.324 0h-11.342v11.4h11.342V0zM130.324 22.8h-11.342v11.4h11.342V22.8zM175.699 45.6h-11.343v11.399h11.343v-11.4z"
							  fill="#4EA2D6"></path>
						<path pid="4" d="M164.354 45.6h-11.342l11.342 11.399v-11.4z" fill="#0B82B6"></path>
						<path pid="5"
							  d="M141.667 34.2h-11.343V57h11.343V34.2zM141.668 11.4h-11.343l-.001 11.4 5.672 5.7 5.672-5.7V11.4z"
							  fill="#73BCE4"></path>
						<path pid="6"
							  d="M130.325 11.4h11.343L130.325 0v11.4zM76.483 11.4h11.343L76.483 0v11.4zM130.325 34.2h11.343l-11.343-11.4v11.4z"
							  fill="#0B82B6"></path>
						<path pid="7" d="M198.388 45.6h11.342V0h-11.342v45.6z" fill="#73BCE4"></path>
						<path pid="8" d="M226.745 45.6H209.73v11.399h17.015v-11.4z" fill="#4EA2D6"></path>
						<path pid="9" d="M209.729 45.6h-11.342l11.342 11.399v-11.4z" fill="#0B82B6"></path>
						<path pid="10" d="M187.042 45.6H175.7V0h11.342v45.6z" fill="#73BCE4"></path>
						<path pid="11" d="M175.7 45.6h11.343L175.7 56.998v-11.4z" fill="#0B82B6"></path>
						<path pid="12" d="M232.417 45.6h11.342V0h-11.342v45.6z" fill="#73BCE4"></path>
						<path pid="13"
							  d="M297.657 56.999H309l-11.332-28.41-5.683-14.34L286.313 0h-11.342l11.343 28.501 5.671 14.248L297.657 57z"
							  fill="#4EA2D6"></path>
						<path pid="14"
							  d="M286.313 56.999h-11.342l11.343-28.498 5.671-14.251L297.657 0H309l-11.332 28.588-5.683 14.161L286.313 57z"
							  fill="#73BCE4"></path>
						<path pid="15" d="M255.104 45.6h-11.342v11.399h11.342v-11.4z" fill="#4EA2D6"></path>
						<path pid="16" d="M243.758 45.6h-11.341l11.341 11.399v-11.4z" fill="#0B82B6"></path>
						<path pid="17" d="M266.447 45.6h-11.343V0h11.343v45.6z" fill="#73BCE4"></path>
						<path pid="18" d="M255.104 45.6h11.343l-11.343 11.399v-11.4z" fill="#0B82B6"></path>
						<path pid="19" d="M33.965 0H22.623l-5.7 31.357L11.343 57h11.342l11.28-57z"
							  fill="#73BCE4"></path>
						<path pid="20" d="M0 0h11.342l5.582 31.357L22.623 57H11.28L0 0z" fill="#4EA2D6"></path>
						<path pid="21"
							  d="M16.923 31.357L11.343 57h11.342l-5.762-25.642zM291.986 14.25l-5.671 14.252 5.671 14.248 5.684-14.14-5.684-14.36z"
							  fill="#0B82B6"></path>
					</svg>
					<div class="d-none d-md-block ml-4">Коммерческие предложения</div>
				</div>
			</div>
			<div class="header__right d-flex align-center">
				<router-link to="/" class="d-none d-sm-inline mr-4">{{ userEmail }}</router-link>
				<span class="d-none d-sm-inline mr-4">{{ userName }}</span>
				<!--        <router-link :to="{name:'profile'}" class="header__avatar">
						  <v-icon>
							mdi-account-circle
						  </v-icon>
						</router-link>-->
			</div>
		</div>

	</v-app-bar>
</template>

<script>
export default {
	name: "the-header",
	data: (vm) => ({
		newAgentId: null,
	}),
	computed: {
		user() {
			return this.$store.state.session?.user;
		},
		userName() {
			return this.user?.NAME;
		},
		userEmail() {
			return this.user?.EMAIL;
		},
		agentItems() {
			return this.user.agents?.map(el => ({
				text: el.NAME,
				value: el.ID
			}));
		}
	},
	methods: {
		toggleDrawer() {
			this.$store.state.withDrawer = !this.$store.state.withDrawer;
		},
		onChangeAgent(item) {
			if (this.hasChanges) {
				this.$store.state.isDialogChanges = true;
			} else {
				this.$store.commit("setAgentId", this.newAgentId);
				//if (this.$route.name === "orders") this.$router.go();
				//else this.$router.push({name: "orders"});
			}
		}
	},
	created() {
		this.newAgentId = this.$store.state.session.agentId;
	}
}
</script>

<style lang="scss">
.header {
	height: 128px;

	&__wrapper {
		width: 100%;
		height: 100%;
	}

	&__avatar {
		text-decoration: none;
	}

	/*a {
	  display: block;
	}*/

	img {
		display: block;
		//width: 60px;
		height: 50px;
	}
}
</style>
